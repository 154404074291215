import React, { useState, useEffect } from 'react';

import { Send, Mail, Twitter, Instagram } from 'react-feather';

import Wrap from '../components/Wrap';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const styles = require('./Contact-styles.module.css');

const Contact = props => {
  const [textAreaValue, setTextAreaValue] = useState(props.message);

  const [mailSent, setMailSent] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    setTextAreaValue(props.message);
  }, [props.message]);

  const handleOnChange = e => {
    setTextAreaValue(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const name = e.target.elements[0].value;
    const email = e.target.elements[1].value;
    const message = e.target.elements[2].value;

    if (name && email && message) {
      const link = 'https://hooks.zapier.com/hooks/catch/2447005/n86hdh/';
      const data = {
        name,
        email,
        message,
      };
      const result = await fetch(link, { method: 'POST', body: JSON.stringify(data) });

      if (result.ok) {
        setMailSent(true);
        setStatus('ok');
        setMessage(`<h3>Message sent</h3><p>We'll contact you via e-mail soon.</p>`);
      } else {
        setMailSent(false);
        setStatus('error');
        setMessage(`<h3>Message not sent!</h3><p>There was an error sending this form. please try again later.</p>`);
      }
    } else {
      setMailSent(false);
      setStatus('error');
      setMessage(`<h3>Request not sent!</h3><p>All form fields are required</p>`);
    }
  };

  return (
    <Wrap id="contact">
      <Pos>05</Pos>
      <h1>Contact</h1>
      <h2>How to reach us</h2>

      <div className={styles.socials}>
        <p>
          <a href="mailto:contact@firstversionist.com" aria-label="Email us">
            <Mail size={32} strokeWidth="1" />
          </a>
        </p>
        <p>
          <a href="https://twitter.com/firstversionist" aria-label="Follow us on Twitter">
            <Twitter size={32} strokeWidth="1" />
          </a>
        </p>
        <p>
          <a href="https://instagram.com/firstversionist" aria-label="Follow us on Instagram">
            <Instagram size={32} strokeWidth="1" />
          </a>
        </p>
      </div>
      {false ? (
        <form className={styles.contactForm} id="contactForm" onSubmit={handleSubmit}>
          {mailSent && status === 'ok' && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {!mailSent && (
            <div>
              <label htmlFor="name">Name *</label>
              <input type="text" defaultValue="" name="name" id="name" required />

              <label htmlFor="email">E-mail *</label>
              <input type="email" defaultValue="" name="email" id="email" required />

              <label htmlFor="msg">Message *</label>
              <textarea name="msg" id="msg" required value={textAreaValue} onChange={handleOnChange} />
              <small>* required</small>

              {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}

              <button type="submit">
                Send message <Send size={32} strokeWidth="1" />
              </button>
            </div>
          )}
        </form>
      ) : null}
      <PrevNext prevHref="#values" prev="Our values" />
    </Wrap>
  );
};

export default Contact;
