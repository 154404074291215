import React, { useState } from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from './Link';

const styles = require('./Foreground-styles.module.css');

const detailsQuery = graphql`
  query MenuQuery {
    site {
      siteMetadata {
        title
        company
        menuLinks {
          name
          link
        }
      }
    }
    file: file(relativePath: { eq: "images/icon.png" }) {
      childImageSharp {
        fixed(width: 64) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Foreground = () => {
  const [menuvisible, setmenuvisible] = useState(false);

  const toggleMenu = () => {
    setmenuvisible(!menuvisible);
  };

  const buttonToggle = e => {
    e.preventDefault();
    toggleMenu();
  };

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => (
        <>
          <div
            className={styles.foreground}
            data-aos="fade"
            data-aos-easing="ease-in-sine"
            data-aos-delay="100"
            data-aos-duration="600"
          >
            <button
              type="button"
              aria-label="Show or hide menu"
              className={[styles.menuButton, menuvisible && styles.menuButtonOpen].filter(x => x).join(' ')}
              onClick={buttonToggle}
            >
              <div />
            </button>
            <div className={styles.dot} id="dot" />
          </div>
          <nav className={[styles.menu, menuvisible && styles.menuopen].filter(x => x).join(' ')}>
            <ul>
              <li>
                <Img fixed={data.file.childImageSharp.fixed} />
              </li>
              <li>
                <Link to="#header" onClick={toggleMenu}>
                  Start
                </Link>
              </li>
              {data.site.siteMetadata.menuLinks.map(link => (
                <li key={link.name}>
                  <Link to={link.link} onClick={toggleMenu}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </>
      )}
    />
  );
};

export default Foreground;
