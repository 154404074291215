import React from 'react';


const styles = require('./Pos-styles.module.css');

const Pos = props => (
  <div className={styles.pos} style={{ color: props.color }} {...props}>
    {props.children}
  </div>
);

export default Pos;
