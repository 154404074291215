import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Wrap from '../components/Wrap';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const styles = require('./Products-styles.module.css');

const detailsQuery = graphql`
  query ProductsQuery {
    polypane: file(relativePath: { eq: "images/polypane.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    polypaneIcon: file(relativePath: { eq: "images/polypane-icon.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    superposition: file(relativePath: { eq: "images/superposition.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    superpositionIcon: file(relativePath: { eq: "images/superposition-icon.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fromscratch: file(relativePath: { eq: "images/fromscratch.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    fromscratchIcon: file(relativePath: { eq: "images/fromscratch-icon.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fixa11y: file(relativePath: { eq: "images/fixa11y.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    fixa11yIcon: file(relativePath: { eq: "images/fixa11y-icon.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Products = () => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => (
        <Wrap id="products" styleHint="paddingLess">
          <Pos>01</Pos>

          <div className={styles.innerWrap}>
            <h1 data-aos="fade" data-aos-offset="50" data-aos-duration="250">
              Products
            </h1>
            <h2 data-aos="fade" data-aos-offset="50" data-aos-delay="100" data-aos-duration="600">
              The things we build and publish
            </h2>
            <p data-aos="fade" data-aos-offset="50" data-aos-delay="200" data-aos-duration="600">
              Firstversionist empowers developers, designers and other web workers with powerful software that helps
              them get work done faster and better.
            </p>
          </div>

          <div className={styles.pol} data-aos="fade-up" data-aos-offset="50" data-aos-duration="250">
            <div data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="600">
              <Img
                alt="The Polypane application"
                className={styles.screenshot}
                fluid={data.polypane.childImageSharp.fluid}
                style={{ maxWidth: data.polypane.childImageSharp.fluid.presentationWidth }}
              />
              <Img className={styles.icon} fixed={data.polypaneIcon.childImageSharp.fixed} alt="" />
            </div>
            <div>
              <h3>Polypane</h3>
              <h4>The browser for web development</h4>
              <p>A browser built from the ground up to create websites and apps.</p>
              <a className={styles.button} href="https://polypane.rocks">
                Get it
              </a>
            </div>
          </div>

          <div className={styles.sup} data-aos="fade-up" data-aos-offset="50" data-aos-duration="250">
            <div data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="600">
              <Img
                alt="The Superposition application"
                className={styles.screenshot}
                fluid={data.superposition.childImageSharp.fluid}
                style={{ maxWidth: data.superposition.childImageSharp.fluid.presentationWidth }}
              />
              <Img className={styles.icon} fixed={data.superpositionIcon.childImageSharp.fixed} alt="" />
            </div>
            <div>
              <h3>Superposition</h3>
              <h4>Use the design system you already have</h4>
              <p>Extract design tokens from websites. Export them to code and use them in your favorite design tool.</p>
              <a className={styles.button} href="https://superposition.design">
                Free download
              </a>
            </div>
          </div>

          <div className={styles.fix} data-aos="fade-up" data-aos-offset="50" data-aos-duration="250">
            <div data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="600">
              <Img
                alt="Fixa11y UI with options to set the minimum text contrast"
                className={styles.screenshot}
                fluid={data.fixa11y.childImageSharp.fluid}
                style={{ maxWidth: data.fromscratch.childImageSharp.fluid.presentationWidth }}
              />
              <Img className={styles.icon} fixed={data.fixa11yIcon.childImageSharp.fixed} alt="" />
            </div>
            <div>
              <h3>FixA11y</h3>
              <h4>Browser extensions to improve accessibility</h4>
              <p>The Fix Contrast browser extension automatically fixes text contrast issues on websites.</p>
              <a className={styles.button} href="https://fixa11y.com">
                Free download
              </a>
            </div>
          </div>

          <div className={styles.fro} data-aos="fade-up" data-aos-offset="50" data-aos-duration="250">
            <div data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="600">
              <Img
                alt="The FromScratch application window"
                className={styles.screenshot}
                fluid={data.fromscratch.childImageSharp.fluid}
                style={{ maxWidth: data.fromscratch.childImageSharp.fluid.presentationWidth }}
              />
              <Img className={styles.icon} fixed={data.fromscratchIcon.childImageSharp.fixed} alt="" />
            </div>
            <div>
              <h3>FromScratch</h3>
              <h4>Auto-saving scratchpad</h4>
              <p>A simple but smart note-taking app that automatically saves your changes</p>
              <a className={styles.button} href="https://fromscratch.rocks">
                Free download
              </a>
            </div>
          </div>

          <div className={styles.innerWrap}>
            <p data-aos="fade" data-aos-offset="50" data-aos-duration="600">
              We have also built <a href="https://twitter.com/telegraafst">neural networks for twitter bots</a>,{' '}
              <a href="https://m.me/amessengeradventure">chatbot text adventures</a> and publish many{' '}
              <a href="https://www.npmjs.com/~kilianvalkhof">open source</a>{' '}
              <a href="https://github.com/kilian">projects</a> used by millions of developers worldwide.
            </p>
          </div>

          <PrevNext nextHref="#aboutus" next="About us" />
        </Wrap>
      )}
    />
  );
};
export default Products;
