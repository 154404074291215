import React from 'react';


import Wrap from '../components/Wrap';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const styles = require('./Values-styles.module.css');

const Values = () => {
  return (
    <Wrap id="values">
      <Pos>03</Pos>
      <h1 data-aos="fade" data-aos-duration="600">
        Our Values
      </h1>
      <h2 data-aos="fade" data-aos-duration="600">
        What we stand for, who we are
      </h2>
      <ol className={styles.valueList}>
        <li data-aos="fade-up" data-aos-duration="600">
          <h3>Simplify</h3>
          <p>This actually said "Simplify, simplify, simplify", but it didn't fit our values.</p>
        </li>
        <li data-aos="fade-up" data-aos-duration="600">
          <h3>Innovating is fun</h3>
          <p>
            The only way to make innovative products together is to be open to strange new ideas. You need to try and
            fail and try again and have fun along the way.
          </p>
        </li>
        <li data-aos="fade-up" data-aos-duration="600">
          <h3>Solve real problems</h3>
          <p>
            VR, AR, Blockchain, AI and Machine Learning are fascinating technologies that we are heavily experimenting
            with, But we're here to solve real problems, not look for projects to jam cool technologies into.
          </p>
        </li>
        <li data-aos="fade-up" data-aos-duration="600">
          <h3>Done is better than perfect</h3>
          <p>
            The sooner something gets in front of real users, the sooner you'll realise that your perception of
            "perfect" was <em>way off</em>. So lets work to "done" and iterate.
          </p>
        </li>
        <li data-aos="fade-up" ddata-aos-duration="600">
          <h3>Be kind</h3>
          <p>
            If you want to innovate you can only do so with a team of honest, positive people that want each other to
            succeed. We'll walk away if that environment isn't there, and we hope you would too.
          </p>
        </li>
      </ol>
      <PrevNext prevHref="#aboutus" prev="About us" nextHref="#contact" next="Contact" />
    </Wrap>
  );
};

export default Values;
