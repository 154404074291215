import React from 'react';


import { UserCheck, Radio, TrendingUp } from 'react-feather';

import Wrap from '../components/Wrap';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const styles = require('./Services-styles.module.css');

const Services = props => {
  const sendMessage = msg => {
    props.setMailMessage(msg);
  };

  const sendDesignSprintMsg = () => sendMessage('I would like to request a quote for a design sprint...');
  const sendPrototypeMsg = () => sendMessage('I would like to request a quote for a prototype...');
  const sendMVPMsg = () => sendMessage('I would like to request a quote for an MVP...');

  return (
    <Wrap id="services" styleHint="white">
      <Pos color="#000">04</Pos>
      <h1 data-aos="fade" data-aos-duration="600" className={styles.h1}>
        Services
      </h1>
      <h2 data-aos="fade" data-aos-duration="600" data-aos-delay="200" className={styles.h2}>
        How we increase your speed of innovation
      </h2>

      <p data-aos="fade" data-aos-duration="600" data-aos-delay="400">
        At Firstversionist we specialise in greenfield projects. Because of this, the services we offer are all geared
        towards early-stage creation. Check if a service matches the stage you're at by reading the <em>Good for</em>{' '}
        description. Don't hesitate to ask us for advice, we're happy to help.
      </p>

      <div className={styles.services}>
        <div data-aos="fade-up" data-aos-duration="600" className={[styles.service, styles.av].join(' ')}>
          <UserCheck size={32} strokeWidth="1" />
          <h3>Design sprint</h3>
          <span>Starts at €5000</span>
          <p>
            We will facilitate a design sprint with your internal team and stakeholders. In 4 days we will go from a
            problem/opportunity in your organisation to a finished prototype ready to test.
          </p>
          <em>
            Good for: companies that are looking to solve a general problem and have no specific solution in mind.
          </em>
          <div className={styles.buttonContainer}>
            <a className={styles.button} href="#contactForm" onClick={sendDesignSprintMsg}>
              Request quote
            </a>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="600" data-aos-delay="200" className={styles.service}>
          <Radio size={32} strokeWidth="1" />
          <h3>Prototype</h3>
          <span>Starts at €8000</span>
          <p>
            We'll create a prototype based on a mutually defined problem. Prototypes are not production ready but can be
            used to validate the idea and the solution before moving on to an MVP.
          </p>
          <em>
            Good for: companies that are looking to solve specific problems and have a general idea of the preferred,
            acceptable solution.
          </em>
          <div className={styles.buttonContainer}>
            <a className={styles.button} href="#contactForm" onClick={sendPrototypeMsg}>
              Request quote
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-delay="400"
          className={[styles.service, styles.tl].join(' ')}
        >
          <TrendingUp size={32} strokeWidth="1" />
          <h3>MVP</h3>
          <span>Starts at €15000</span>
          <p>
            We will set up and manage a project to define, design and build a <b>minimum viable product</b> to solve a
            problem supported by a business case or user stories. The MVP is a production ready solution.
          </p>
          <em>
            Good for: companies that have specific problem areas and a preferred way to solve them, supported by a
            business case or user stories.
          </em>
          <div className={styles.buttonContainer}>
            <a className={styles.button} href="#contactForm" onClick={sendMVPMsg}>
              Request quote
            </a>
          </div>
        </div>
      </div>

      <p data-aos="fade" data-aos-duration="600" className={styles.additional}>
        <em>Additionally we can provide strategic guidance sessions, contact us for more information.</em>
      </p>
      <PrevNext color="#000" prevHref="#values" prev="Our values" nextHref="#contact" next="Contact" />
    </Wrap>
  );
};

export default Services;
