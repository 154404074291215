import React from 'react';

import video from '../../assets/video/vid.mp4';

const styles = require('./Background-styles.module.css');

const Background = () => {
  return (
    <div className={styles.background}>
      <video
        muted
        playsInline
        autoPlay
        data-aos="fade"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        data-aos-duration="600"
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default Background;
