import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Wrap from '../components/Wrap';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const styles = require('./Aboutus-styles.module.css');

const detailsQuery = graphql`
  query aboutQuery {
    file: file(relativePath: { eq: "images/avatar.jpg" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Aboutus = () => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => (
        <Wrap id="aboutus" styleHint="white">
          <Pos color="#000">02</Pos>
          <h1 data-aos="fade-up" data-aos-delay="0" data-aos-duration="600">
            About us
          </h1>
          <h2 data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">
            What we do, and how we do it
          </h2>
          <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="600">
            <p>
              Call us a design studio, a product studio, a prototyping studio or a full-stack creation company,{' '}
              <strong>we're here to increase the speed of innovation worldwide</strong>, through our own applications
              and through solutions made for and with partners.
            </p>

            <p>
              We <strong>ideate, design, validate, develop, test and launch</strong> concepts, and can help you with any
              of those stages. We work best in greenfield projects (hence the name!) with partners that are open to
              innovation and user centered design. We provide strategic guidance at each step of the process, taking
              both your unique business context as well as the larger outside context in consideration.
            </p>

            <p>
              Project we can create include web, desktop and mobile applications, AR and VR experiences, chatbots and
              projects that require AI or Machine learning to provide engaging user experiences. As{' '}
              <strong>computational designers</strong> we use these technologies to inform our design and combine that
              with a firm understanding of user experience design, service design and accessibility. As a studio, we are
              open to creative capital constructions.
            </p>

            <p className={styles.me}>
              <Img fixed={data.file.childImageSharp.fixed} />
              <br />
              Firstversionist is the company representing <a href="https://kilianvalkhof.com">Kilian Valkhof</a>.
            </p>
          </div>

          <PrevNext color="#000" prevHref="#products" prev="Products" nextHref="#values" next="Our values" />
        </Wrap>
      )}
    />
  );
};

export default Aboutus;
