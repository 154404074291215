import React, { useState, useEffect, useRef } from 'react';
import { AnimateOnChange } from '@nearform/react-animation';

import Logo from '../components/logo.svg';
import PrevNext from '../components/prevNext';
import Pos from '../components/Pos';

const taglines = [
  'We build prototypes',
  'We improve productivity',
  'We conceive new concepts',
  // 'We host design sprints',
  'We validate ideas',
  'We build MVPs',
  'We create first versions',
];

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const styles = require('./Header-styles.module.css');

const Header = () => {
  const [taglineIndex, setTaglineIndex] = useState(0);
  const [tagline, setTagline] = useState(taglines[taglineIndex]);

  useInterval(() => {
    const newTaglineIndex = (taglineIndex + 1) % taglines.length;
    setTaglineIndex(newTaglineIndex);
    setTagline(taglines[newTaglineIndex]);
  }, 4000);

  return (
    <header id="header" className={styles.header}>
      <Pos data-aos="fade" data-aos-offset="200" data-aos-delay="200" data-aos-duration="600">
        00
      </Pos>
      <div className={styles.logoArea} data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">
        <Logo />
        <h1>FirstVersionist</h1>
        <h2>{AnimateOnChange && <AnimateOnChange durationOut={750}>{tagline}</AnimateOnChange>}</h2>
      </div>
      <PrevNext
        nextHref="#products"
        next="Products"
        data-aos="fade"
        data-aos-offset="0"
        data-aos-delay="200"
        data-aos-duration="600"
      />
    </header>
  );
};

export default Header;
