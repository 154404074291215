import React, { useState, useEffect, useRef } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import SEO from '../components/seo';

import Background from '../components/Background';
import Foreground from '../components/Foreground';

import Header from '../sections/Header';
import Products from '../sections/Products';
import Aboutus from '../sections/Aboutus';
import Values from '../sections/Values';
import Services from '../sections/Services';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';

import initCanvas from '../components/canvas';

const styles = require('./index-styles.module.css');

const IndexPage = () => {
  const canvas = useRef(null);

  const [mailMessage, setMailMessage] = useState('');
  const [canvasInitialized, setCanvasInitialized] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!canvasInitialized) {
        AOS.init({
          duration: 600,
          easing: 'ease-in-sine',
        });
        initCanvas(canvas.current);
        setCanvasInitialized(true);
      } else {
        AOS.refreshHard();
      }
    }
  });

  return (
    <>
      <SEO title="" />
      <Background />
      <Foreground />

      <Header />
      <main className={styles.main}>
        <Products />
        <Aboutus />
        <Values />
        <Contact message={mailMessage} />
        <Footer />
      </main>
      <canvas ref={canvas} className={styles.canvas} resize="resize" />
    </>
  );
};

export default IndexPage;
